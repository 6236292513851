// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';
import { CreditNoteSave } from '../components/invoice/types/credit-note.types';

@Injectable({
    providedIn: 'root',
})
export class CreditNoteService extends APIRequest {
    constructor(http: HttpClient)  {
        super(http, APIRequestResources.SavindaAPI); 
    }

    saveCreditNote(creditNote: CreditNoteSave): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'credit-note'
        };
        return this.post<any>(creditNote,options);
    }    
    
    getCreditNoteById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'credit-note',
            suffix:id
        };
        return this.get<any>(options);
    }    
    
    find(searchParams: any): Observable<APIResponse<PaginationResponse<any[]>>>{
        const options: APIRequestOptions = {
            endpoint:'credit-note/find',
            params: searchParams
        };
        return this.get<PaginationResponse<any[]>>(options);
    }

    // getInvoiceTypeById(id: string): Observable<APIResponse<any>> {
    //     const options: APIRequestOptions = {
    //         endpoint:'invoice-type',
    //         suffix:id
    //     };
    //     return this.get<any>(options);
    // } 

    // getManagementReport(params: any): Observable<APIResponse<any[]>> {
    //     const options: APIRequestOptions = {
    //         params: params,
    //         endpoint:"invoice/invoiceMgtReport"
    //     };
    //     return this.get<any[]>(options);
    // }
}