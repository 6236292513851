import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class StockService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    saveInventoryItems(inventoryItem: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "barcode/stock"
        };
        return this.post<any>(inventoryItem, options);
    }

    getItemsByBarcode(barcode: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'barcode/stock',
            suffix: barcode
        };
        return this.get<any>(options);
    }

    getItemsByItemId(id: string): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint: 'barcode/stock-inventory',
            suffix: id
        };
        return this.get<any[]>(options);
    }
}